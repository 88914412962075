import type { Breadcrumbs } from '@/type/breadcrumbs'

export const useBreadCrumb = defineStore('breadcrumb', {
  state: () => ({
    breadCrumbItems: [] as Breadcrumbs[],
  }),
  actions: {
    setBreadCrumb(item: Breadcrumbs[]) {
      this.breadCrumbItems = item
    },
  },
})
